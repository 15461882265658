export const weatherData2 = [{
    month: 'Controle',
    avgT: 9.1,
    avgLowT: 9.1,
    avgHighT: 19.1,
    avgH: 22,
  }, {
    month: 'Produto',
    avgT: 1.7,
    avgLowT: 7.8,
    avgHighT: 3.6,
    avgH: 60,
  }];
  