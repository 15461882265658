export const weatherData = [{
     month: 'Controle',
    avgT: 4.1,
    avgLowT: 0.01,
    avgHighT: 0.03,
    avgH: 3.1,
  }, {
    month: 'Produto',
    avgT: 4.9,
    avgLowT: 0.03,
    avgHighT: 0.04,
    avgH: 4,
  }];
  