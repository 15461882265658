<template>
  <div class="grid">
    <div class="col-12 xl:col-12">
      <div class="card">
        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->

        <ProjectInfo />

        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <BodyHeader />
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- Accordion Tab ----------------------------------------------------------------------------------------------------------------------------------->
        <div class="grid" style="padding-top: 20px; justify-content: center">
          <div
            class="col-12 xl:col-12 sm:col-12 md:col-12 .col-12"
            style="text-align: center"
          >
            <div>
              <div
                class="grid"
                style="
                  box-sizing: border-box;
                  background: rgb(255, 255, 255);
                  border: 2px solid rgb(245, 246, 247);
                  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
                  border-radius: 18px;
                  justify-content: center;
                "
              >
                <div class="col-12"></div>
                <div
                  class="col-12 flex"
                  style="justify-content: center; align-items: center"
                >
                  <Image
                    src="/images/locIcon.svg"
                    alt="Image"
                    width="30"
                    style="padding-right: 10px; padding-top: 10px"
                  />
                  <h5
                    style="
                      font-style: normal;
                      font-weight: 600;
                      font-size: 15px;
                      line-height: 18px;
                      color: #466107;
                      display: flex;
                      align-items: center;
                      padding-right: 20px;
                    "
                  >
                    Todas as Áreas
                  </h5>
                  <Image
                    src="/images/logoVeg.svg"
                    alt="Image"
                    width="30"
                    style="padding-right: 10px; padding-top: 10px"
                  />
                  <h5
                    style="
                      font-style: normal;
                      font-weight: 600;
                      font-size: 15px;
                      line-height: 18px;
                      color: #466107;
                      display: flex;
                      align-items: center;
                    "
                  >
                    Vegetativo
                  </h5>
                </div>
                <div
                  class="col-12 xl:col-5 md:col-12 lg:col-12 sm:col-12 .col-12 .col-sm-12"
                >
                  <span class="titleChart">Bactérias</span>
                  <div class="flex" style="justify-content: space-around">
                    <div>
                     <!--  <Image
                        src="/images/asterisco.svg"
                        alt="Image"
                        width="10"
                      /> -->
                    </div>
                    <div>
                     <Image
                        src="/images/asterisco.svg"
                        alt="Image"
                        width="10"
                      />
                    </div>
                  </div>
                  <DxChart
                    id="chart"
                    :data-source="weatherData"
                    default-pane="bottom"
                    :palette="[
                      '#415C02',
                      '#415C02',
                      '#777777',
                      '#777777',
                      '#777777',
                    ]"
                  >
                    <DxCommonSeriesSettings argument-field="month" />
                    <DxSeries
                      pane="bottom"
                      value-field="avgT"
                      type="bar"
                      name="Organismos"
                    >
                      <DxValueErrorBar
                      :value="0.05"
                        value-field="0.01"
                        :line-width="2"
                        type="fixed"
                      />
                    </DxSeries>

                    <DxPane name="bottom" />

                    <DxArgumentAxis>
                      <DxLabel />
                    </DxArgumentAxis>
                    <DxValueAxis pane="top">
                      <DxGrid :visible="true" />
                    </DxValueAxis>
                    <DxValueAxis>
                      <DxGrid :visible="false" />
                      <DxTitle text="índice de Shannon" />
                    </DxValueAxis>

                    <DxLegend
                      vertical-alignment="bottom"
                      horizontal-alignment="center"
                      :visible="false"
                    />
                    <DxExport :enabled="false" />
                    <DxTooltip
                      :enabled="true"
                      :customize-tooltip="customizeTooltip"
                    />
                  </DxChart>
                </div>
                <div
                  class="col-12 xl:col-5 md:col-12 lg:col-12 sm:col-12 .col-12 .col-sm-12"
                  style="padding-left: 45px"
                >
                  <span class="titleChart">Fungos</span>
                  <!-- <div class="flex" style="justify-content: space-around">
                    <div>
                       <Image src="/images/asterisco.svg" alt="Image" width="10" />
                    </div>
                    <div>
                       <Image
                        src="/images/asterisco.svg"
                        alt="Image"
                        width="10"
                      /> 
                    </div>
                  </div> -->
                  <DxChart
                    id="chart"
                    :data-source="weatherData"
                    default-pane="bottom"
                    :palette="[
                      '#415C02',
                      '#415C02',
                      '#777777',
                      '#777777',
                      '#777777',
                    ]"
                  >
                    <DxCommonSeriesSettings argument-field="month" />
                    <DxSeries
                      pane="bottom"
                      value-field="avgH"
                      type="bar"
                      name="Organismos"
                    >
                      <DxValueErrorBar
                        :value="0.5"
                        :line-width="2"
                        type="fixed"
                      />
                    </DxSeries>

                    <DxPane name="bottom" />

                    <DxArgumentAxis>
                      <DxLabel />
                    </DxArgumentAxis>
                    <DxValueAxis pane="top">
                      <DxGrid :visible="true" />
                    </DxValueAxis>
                    <DxValueAxis>
                      <DxGrid :visible="false" />
                      <DxTitle text="índice de Shannon" />
                    </DxValueAxis>

                    <DxLegend
                      vertical-alignment="bottom"
                      horizontal-alignment="center"
                      :visible="false"
                    />
                    <DxExport :enabled="false" />
                    <DxTooltip
                      :enabled="true"
                      :customize-tooltip="customizeTooltip"
                    />
                  </DxChart>
                </div>
                <div
                  class="col-6 xl:col-1 md:col-12 lg:col-12 sm:col-12"
                  v-if="project.typeProject === '01'"
                >
                  <Button
                    label="Filtrar"
                    icon="pi pi-filter"
                    @click="openModal"
                  />
                  <Dialog
                    header="Filtrar"
                    v-model:visible="displayModal"
                    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
                    style="width: 55vw; text-align: center"
                    :modal="true"
                  >
                    <span
                      style="
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 23px;
                        color: #585857;
                      "
                      >Selecione as variáveis que deseja avaliar no gráfico.
                    </span>
                    <div class="grid cardFilter">
                      <div
                        class="field-radiobutton col-12 xl:col-5 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="Controle"
                          name="Controle"
                          value="Controle"
                          v-model="filterType"
                          @click="ChangeVariableFilter1"
                        />
                        <label
                          for="city1"
                          style="display: flex; align-items: center"
                          class="city filter-text"
                        >
                          <Image
                            src="/images/CPIcon.svg"
                            alt="Image"
                            width="45"
                            style="padding-right: 5px"
                          /><span style="margin: 22px"
                            >Controle x Produto</span
                          ></label
                        >
                      </div>
                      <div
                        class="field-radiobutton col-12 xl:col-5 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="Vegetativo"
                          name="Vegetativo"
                          value="Vegetativo"
                          v-model="filterType"
                          @click="ChangeVariableFilter2"
                        />
                        <label
                          for="city2"
                          style="display: flex; align-items: center"
                          class="city filter-text"
                        >
                          <Image
                            src="/images/VFIcon.svg"
                            alt="Image"
                            width="55"
                            style="padding-right: 5px"
                          />
                          <span style="margin: 22px">
                            Vegetativo x Reprodutivo
                          </span></label
                        >
                      </div>
                    </div>
                    <span
                      style="
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 23px;
                        color: #585857;
                      "
                      >Selecione a área.</span
                    >
                    <div class="grid cardFilter">
                      <div
                        class="field-radiobutton col-12 xl:col-3 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="AllAreas"
                          name="AllAreas"
                          value="AllAreas"
                          v-model="filterarea"
                          @click="ChangeVariableFilter3"
                        />
                        <label for="allAreas" class="city filter-text">
                          Todas as Áreas</label
                        >
                      </div>
                      <div
                        class="field-radiobutton col-12 xl:col-3 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="firstArea"
                          name="city"
                          value="Area1"
                          v-model="filterarea"
                          @click="ChangeVariableFilter4"
                        />
                        <label for="firstArea" class="city filter-text">
                          Área 1</label
                        >
                      </div>
                      <div
                        class="field-radiobutton col-12 xl:col-3 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="secondArea"
                          name="city"
                          value="Area2"
                          v-model="filterarea"
                          @click="ChangeVariableFilter5"
                        />
                        <label for="secondArea" class="city filter-text">
                          Área 2</label
                        >
                      </div>
                      <div
                        class="field-radiobutton col-12 xl:col-3 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="thirdArea"
                          name="city"
                          value="Area3"
                          v-model="filterarea"
                          @click="ChangeVariableFilter6"
                        />
                        <label for="thirdArea" class="city filter-text">
                          Área 3</label
                        >
                      </div>
                    </div>

                    <span
                      style="
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 23px;
                        color: #585857;
                      "
                      v-if="filterType === 'Controle'"
                      >Selecione o estágio.</span
                    >

                    <span
                      style="
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 23px;
                        color: #585857;
                      "
                      v-if="filterType === 'Vegetativo'"
                      >Selecione o Produto.</span
                    >
                    <div
                      class="grid cardFilter"
                      v-if="filterType === 'Controle'"
                    >
                      <div
                        class="field-radiobutton col-12 xl:col-5 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="Vegetativo"
                          name="Vegetativo"
                          value="Vegetativo"
                          v-model="filterType2"
                          @click="ChangeVariableFilter7"
                        />
                        <label for="city1" class="city filter-text">
                          <Image
                            src="/images/logoVeg.svg"
                            alt="Image"
                            width="30"
                            style="padding-right: 10px"
                          />Vegetativo</label
                        >
                      </div>
                      <div
                        class="field-radiobutton col-12 xl:col-5 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="Florescimento"
                          name="Florescimento"
                          value="Florescimento"
                          v-model="filterType2"
                          @click="ChangeVariableFilter8"
                        />
                        <label for="city2" class="city filter-text">
                          <Image
                            src="/images/florIcon.svg"
                            alt="Image"
                            width="30"
                            style="padding-right: 10px"
                          />Reprodutivo</label
                        >
                      </div>
                    </div>
                    <div
                      class="grid cardFilter"
                      v-if="filterType === 'Vegetativo'"
                    >
                      <div
                        class="field-radiobutton col-12 xl:col-5 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="Controle"
                          name="Controle"
                          value="Controle"
                          v-model="filterType2"
                          @click="ChangeVariableFilter9"
                        />
                        <label for="city1" class="city filter-text">
                          <Image
                            src="/images/control.svg"
                            alt="Image"
                            width="30"
                            style="padding-right: 10px"
                          />Controle</label
                        >
                      </div>
                      <div
                        class="field-radiobutton col-12 xl:col-5 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="Produto"
                          name="Produto"
                          value="Produto"
                          v-model="filterType2"
                          @click="ChangeVariableFilter10"
                        />
                        <label for="city2" class="city filter-text">
                          <Image
                            src="/images/product.svg"
                            alt="Image"
                            width="30"
                            style="padding-right: 10px"
                          />Produto</label
                        >
                      </div>
                    </div>
                    <Button label="Atualizar" @click="attFake1" />
                  </Dialog>
                </div>

                <div
                  class="col-6 xl:col-1 md:col-12 lg:col-12 sm:col-12"
                  v-else
                >
                  <Button
                    label="Filtrar"
                    icon="pi pi-filter"
                    @click="openModal"
                  />
                  <Dialog
                    header="Filtrar"
                    v-model:visible="displayModal"
                    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
                    style="width: 55vw; text-align: center"
                    :modal="true"
                  >
                    <span
                      style="
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 23px;
                        color: #585857;
                      "
                      >Selecione as variáveis que deseja avaliar no gráfico.
                    </span>
                    <div class="grid cardFilter">
                      <div
                        class="field-radiobutton col-12 xl:col-4 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="Controle"
                          name="Controle"
                          value="Controle"
                          v-model="filterType"
                          @click="ChangeVariableFilter1"
                        />
                        <label
                          for="city1"
                          style="display: flex; align-items: center"
                          class="city filter-text"
                        >
                          <Image
                            src="/images/CPIcon.svg"
                            alt="Image"
                            width="45"
                            style="padding-right: 5px"
                          /><span style="margin: 22px"
                            >Controle x Produto</span
                          ></label
                        >
                      </div>
                    </div>
                    <span
                      style="
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 23px;
                        color: #585857;
                      "
                      >Selecione a área.</span
                    >
                    <div class="grid cardFilter">
                      <div
                        class="field-radiobutton col-12 xl:col-3 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="AllAreas"
                          name="AllAreas"
                          value="AllAreas"
                          v-model="filterarea"
                          @click="ChangeVariableFilter3"
                        />
                        <label for="allAreas" class="city filter-text">
                          Todas as Áreas</label
                        >
                      </div>
                      <div
                        class="field-radiobutton col-12 xl:col-3 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="firstArea"
                          name="city"
                          value="Area1"
                          v-model="filterarea"
                          @click="ChangeVariableFilter4"
                        />
                        <label for="firstArea" class="city filter-text">
                          Área 1</label
                        >
                      </div>
                      <div
                        class="field-radiobutton col-12 xl:col-3 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="secondArea"
                          name="city"
                          value="Area2"
                          v-model="filterarea"
                          @click="ChangeVariableFilter5"
                        />
                        <label for="secondArea" class="city filter-text">
                          Área 2</label
                        >
                      </div>
                      <div
                        class="field-radiobutton col-12 xl:col-3 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="thirdArea"
                          name="city"
                          value="Area3"
                          v-model="filterarea"
                          @click="ChangeVariableFilter6"
                        />
                        <label for="thirdArea" class="city filter-text">
                          Área 3</label
                        >
                      </div>
                    </div>

                    <span
                      style="
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 23px;
                        color: #585857;
                      "
                      v-if="filterType === 'Controle'"
                      >Selecione o estágio.</span
                    >

                    <span
                      style="
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 23px;
                        color: #585857;
                      "
                      v-if="filterType === 'Vegetativo'"
                      >Selecione o Produto.</span
                    >
                    <div
                      class="grid cardFilter"
                      v-if="filterType === 'Controle'"
                    >
                      <div
                        class="field-radiobutton col-12 xl:col-4 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="Vegetativo"
                          name="Vegetativo"
                          value="Vegetativo"
                          v-model="filterType2"
                          @click="ChangeVariableFilter7"
                        />
                        <label for="city1" class="city filter-text">
                          <Image
                            src="/images/logoVeg.svg"
                            alt="Image"
                            width="30"
                            style="padding-right: 10px"
                          />Vegetativo</label
                        >
                      </div>
                    </div>
                    <div
                      class="grid cardFilter"
                      v-if="filterType === 'Vegetativo'"
                    >
                      <div
                        class="field-radiobutton col-12 xl:col-5 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="Controle"
                          name="Controle"
                          value="Controle"
                          v-model="filterType2"
                          @click="ChangeVariableFilter9"
                        />
                        <label for="city1" class="city filter-text">
                          <Image
                            src="/images/control.svg"
                            alt="Image"
                            width="30"
                            style="padding-right: 10px"
                          />Controle</label
                        >
                      </div>
                      <div
                        class="field-radiobutton col-12 xl:col-5 md:col-6 lg:col-12 sm:col-12 .col-12"
                      >
                        <RadioButton
                          inputId="Produto"
                          name="Produto"
                          value="Produto"
                          v-model="filterType2"
                          @click="ChangeVariableFilter10"
                        />
                        <label for="city2" class="city filter-text">
                          <Image
                            src="/images/product.svg"
                            alt="Image"
                            width="30"
                            style="padding-right: 10px"
                          />Produto</label
                        >
                      </div>
                    </div>
                    <Button label="Atualizar" @click="attFake1" />
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
          <!-- Accordion Tab ----------------------------------------------------------------------------------------------------------------------------------->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DxChart, {
  DxCommonSeriesSettings,
  DxSeries,
  DxValueErrorBar,
  DxPane,
  DxArgumentAxis,
  DxValueAxis,
  DxExport,
  DxLegend,
  DxLabel,
  DxTitle,
  DxTooltip,
  DxGrid,
} from "devextreme-vue/chart";
import { weatherData } from "../assets/Data/data4.js";
import { weatherData2 } from "../assets/Data/data5.js";
import { weatherData3 } from "../assets/Data/data6.js";
import ProjectInfo from "@/components/ProjectInfo.vue";
import { useProjectsStore } from "@/store/projects";
import BodyHeader from "@/components/Biodiversity/BodyHeader.vue";

export default {
  data() {
    return {
      projectsStore: useProjectsStore(),
      VariableFilter1: "ControleProduto",
      VariableFilter2: "AllAreas",
      VariableFilter3: "Vegetativo",
      URLFilter: "",
      i: 1,
      weatherData,
      weatherData2,
      weatherData3,

      chartOptions: {
        palette: [
          "#7CBAB4",
          "#92C7E2",
          "#75B5D6",
          "#B78C9B",
          "#F2CA84",
          "#A7CA74",
        ],
      },
      selectedType: null,
      loading: true,
      displayModal: false,
      filterarea: "AllAreas",
      filterType: "Controle",
      filterType2: "Vegetativo",
    };
  },
  computed: {
    project() {
      var projectId = this.$route.params.projectId;
      return this.projectsStore.project(projectId);
    },
  },
  mounted() {},
  beforeUnmount() {},
  created() {},
  methods: {
    ChangeVariableFilter1() {
      this.VariableFilter1 = "ControleProduto";
      console.log(this.VariableFilter1);
    },
    ChangeVariableFilter2() {
      this.VariableFilter1 = "VegetativoFlorescimento";
      console.log(this.VariableFilter1);
    },
    ChangeVariableFilter3() {
      this.VariableFilter2 = "AllAreas";
      console.log(this.VariableFilter2);
    },
    ChangeVariableFilter4() {
      this.VariableFilter2 = "Area1";
      console.log(this.VariableFilter2);
    },
    ChangeVariableFilter5() {
      this.VariableFilter2 = "Area2";
      console.log(this.VariableFilter2);
    },
    ChangeVariableFilter6() {
      this.VariableFilter2 = "Area3";
      console.log(this.VariableFilter2);
    },
    ChangeVariableFilter7() {
      this.VariableFilter3 = "Vegetativo";
      console.log(this.VariableFilter3);
    },
    ChangeVariableFilter8() {
      this.VariableFilter3 = "Florescimento";
      console.log(this.VariableFilter3);
    },
    ChangeVariableFilter9() {
      this.VariableFilter3 = "Controle";
      console.log(this.VariableFilter3);
    },
    ChangeVariableFilter10() {
      this.VariableFilter3 = "Produto";
      console.log(this.VariableFilter3);
    },
    getFilter() {
      this.URLFilter =
        this.VariableFilter1 +
        ":" +
        this.VariableFilter2 +
        ":" +
        this.VariableFilter3;
      console.log(this.URLFilter);
    },

    attFake1() {
      this.weatherData = this.weatherData2;
      this.displayModal = false;
    },

    openModal() {
      this.displayModal = true;
      this.weatherData = this.weatherData3;
    },
    closeModal() {
      this.displayModal = false;
    },
  },
  components: {
    ProjectInfo: ProjectInfo,
    BodyHeader: BodyHeader,
    DxChart,
    DxCommonSeriesSettings,
    DxSeries,
    DxValueErrorBar,
    DxPane,
    DxArgumentAxis,
    DxValueAxis,
    DxExport,
    DxLegend,
    DxLabel,
    DxTitle,
    DxTooltip,
    DxGrid,
  },
};
</script>

<style>
p {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-left: 5px;
  margin-top: 3px;
}
.info {
  font-style: normal;
  font-weight: 600;
  font-size: 16.3333px;
  line-height: 16px;
  color: #ffffff;
}

.p-accordion
  .p-accordion-tab:first-child
  .p-accordion-header
  .p-accordion-header-link {
  background: linear-gradient(90deg, #90a956 0%, #405b01 98.79%);
  border-radius: 0px 0px 0px 20px;
  color: white;
}

.p-accordion
  .p-accordion-tab:last-child
  .p-accordion-header:not(.p-highlight)
  .p-accordion-header-link {
  background: linear-gradient(90deg, #90a956 0%, #405b01 98.79%);
  border-radius: 0px 0px 0px 20px;
}

.p-accordion .p-accordion-tab:last-child .p-accordion-content {
  background: #ffffff;
  padding-top: 30px;
  margin-top: -15px;
}

.p-accordion
  .p-accordion-tab:first-child
  .p-accordion-header
  .p-accordion-header-link {
  background: linear-gradient(90deg, #90a956 0%, #405b01 98.79%);
  border-radius: 0px 0px 0px 20px;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140.62%;
  color: #ffffff;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #90a956;
  background: #90a956;
}

.p-button {
  color: #ffffff;
  background: #90a956;
  border: 1px solid #90a956;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}

.filter-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #466107;
}

.cardFilter {
  justify-content: center;
  padding-top: 20px;
  background: #fcfcfc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 14px;
  padding-top: 25px;
  margin-bottom: 20px;
  margin-top: 15px;
  border-radius: 14px;
}

.titleChart {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #466107;
  align-items: center;
  padding-left: 50px;
}
</style>
