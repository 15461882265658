export const weatherData3 = [{
    month: 'Controle',
    avgT: 3.9,
    avgLowT: 1.1,
    avgHighT: 4.3,
    avgH: 0.9,
  }, {
    month: 'Produto',
    avgT: 4.5,
    avgLowT: 1.8,
    avgHighT: 4.4,
    avgH: 0.2,
  }];
  